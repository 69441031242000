.popularCityLinksWrapper {
  --heading-padding: 0 0 var(--space-8x);
  --heading-color: var(--heading-color, --color-white);
  --heading-font-size: var(--font-size-2xl);
  display: grid;
  gap: var(--space-6x);
  grid-template-columns: 1fr;

  @media (--screen-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.popularCityLinksWrapperNoHouses {
  grid-template-columns: 1fr;

  @media (--screen-md) {
    grid-template-columns: 1fr;
  }
}

.popularCityLinksGroup {
  margin-bottom: var(--space-6x);
  flex: 1;
}

.popularCitiesSectionList {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
}
